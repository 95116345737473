import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { DatePicker, Pagination, Popconfirm, Select, Table } from "antd";
import { Components } from "antd/lib/date-picker/generatePicker";
import { NavLink } from "react-router-dom";
import { Input, Col, Row, Button, Space } from "antd";
import { deleteCertificate, getCertificates } from "../../../modules/actions/managerPanelActions";
import { dateFormat } from "../../../common/common";
import logo from "./../../../Images/Logo 2.png";
import { getBranch } from "../../../modules/actions/branchActions";

const { Column } = Table;
const { Option } = Select;

function SmallGemstoneJewellery(props) {
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);

	const [startDate, setStartDate] = useState(new Date("1/1/1970, 12:00:00 AM"));
	const [endDate, setEndDate] = useState(new Date());
	const [searchText, setSearchText] = useState("");
	const [searchBool, setSearchBool] = useState(false);
	const [selectedBranch, setSelectedBranch] = useState("");
	useEffect(() => {
		if (props.userPermissions) {
			setSelectedBranch(props.userPermissions.branchCode)
		}
	}, [props.userPermissions])


	useEffect(() => {
		if (selectedBranch) {
			let sd = dateFormat(startDate);
			let ed = dateFormat(endDate);

			props.getCertificates(selectedBranch, sd, ed, 6, searchText, page, pageSize);
		}
	}, [searchBool, startDate, endDate, selectedBranch, page, pageSize]);

	const [selectedRowKeys, setSelectedRowKeys] = useState("");

	function onSelectChange(selectedRowKeys) {
		setSelectedRowKeys(selectedRowKeys);
	}

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		selections: [
			Table.SELECTION_ALL,
			Table.SELECTION_INVERT,
			Table.SELECTION_NONE,
			{
				key: "odd",
				text: "Select Odd Row",
				onSelect: (changableRowKeys) => {
					let newSelectedRowKeys = [];
					newSelectedRowKeys = changableRowKeys.filter((key, index) => {
						if (index % 2 !== 0) {
							return false;
						}
						return true;
					});
					setSelectedRowKeys(newSelectedRowKeys);
				}
			},
			{
				key: "even",
				text: "Select Even Row",
				onSelect: (changableRowKeys) => {
					let newSelectedRowKeys = [];
					newSelectedRowKeys = changableRowKeys.filter((key, index) => {
						if (index % 2 !== 0) {
							return true;
						}
						return false;
					});
					setSelectedRowKeys(newSelectedRowKeys);
				}
			}
		]
	};

	// select
	const [selectedArr, setSelectedArr] = useState([]);
	const [newSelectedArr, setNewSelectedArr] = useState([]);
	const [removedArr, setRemovedArr] = useState("");
	const selectAll = (check) => {
		if (check) {
			setSelectedArr(props.certificates);
		} else {
			setSelectedArr([]);
		}
	};
	let arr = [];
	const selectSep = (check, data) => {
		if (check === true) {
			console.log("if");
			console.log(selectedArr.indexOf(data));
			setSelectedArr([...selectedArr, data]);
			// arr.push(...data)
		} else {
			console.log(selectedArr.indexOf(data));
			arr.push(selectedArr.splice(selectedArr.indexOf(data), 1));
			let newArr = selectedArr;
			setNewSelectedArr(newArr);
			setRemovedArr(arr);
			// setSelectedArr(newArr)
		}
	};
	// console.log(arr)
	useEffect(() => {
		setSelectedArr(newSelectedArr);
	}, [newSelectedArr, removedArr]);
	useEffect(() => {
		console.log(selectedArr);
	}, [selectedArr, removedArr]);


	const printAll = () => {
		// localStorage.setItem("arr", JSON.stringify(selectedArr));
		let arr = []
		selectedArr.map((a, i) => {
			arr.push(
				a.certificate_number
					? a.certificate_number
					: a.entryNo
						? a.entryNo
						: a.number
							? a.number
							: ""
			)
		})
		window.open(`https://certificates.ggilab.com/?entries=${arr.join()}`);
	};

	const printSingle = (data) => {
		// localStorage.setItem("arr", JSON.stringify([data]));
		window.open(`https://certificates.ggilab.com/?entries=${data.certificate_number
			? data.certificate_number
			: data.entryNo
				? data.entryNo
				: data.number
					? data.number
					: ""}`);
	};

	const [data, setData] = useState([]);
	useEffect(() => {
		// console.log(props.certificates)
		if (Array.isArray(props.certificates)) {
			// console.log(props.certificates)
			setData(props.certificates);
		}
	}, [props.certificates]);

	function DeleteCert(entryNo) {
		let sd = dateFormat(startDate);
		let ed = dateFormat(endDate);

		props.deleteCertificate(
			entryNo,
			props.permission.branchCode,
			sd,
			ed,
			6,
			searchText,
			page,
			pageSize
		);
	}
	useEffect(() => {
		props.getBranch(props.permission.branchCode);
	}, [props.permission.branchCode])

	return (
		<div className="diamondGrading smallJewellery">
			<div className="innerDiamondGrading">
				<div className="filter">
					<Row>
						<Col xs={8} sm={8} md={8} lg={8} xl={8} style={{ marginRight: 5 }}>
							<div className="inputDiv" style={{ display: "flex", width: "100%" }}>
								<label style={{ width: "100%" }}>
									<Input.Search
										enterButton

										onSearch={(e) => {
											console.log(e)
											setSearchText(e)
											setSearchBool(searchBool ? false : true)
										}}
										placeholder="Enter Code"
									/>
								</label>
							</div>
						</Col>
						<Col xs={4} sm={4} md={4} lg={4} xl={4} style={{ marginRight: 10 }}>
							<Space direction="vertical" className="w100P">
								<DatePicker onChange={(e) => setStartDate(e)} className="w100P" />
							</Space>
						</Col>
						<Col xs={4} sm={4} md={4} lg={4} xl={4}>
							<Space direction="vertical" className="w100P">
								<DatePicker onChange={(e) => setEndDate(e)} className="w100P" />
							</Space>
						</Col>
						{props.userPermissions.userType === "Admin" && (
							<Col xs={4} sm={4} md={4} lg={4} xl={4}>
								<Select
									value={selectedBranch}
									onChange={(e) => setSelectedBranch(e)}
									className="select w100P"
								>
									{props.branch
										? props.branch.map((a, i) => {
											return <Option value={a.branchCode}>{a.branchName}</Option>;
										})
										: null}
								</Select>
							</Col>
						)}
						<Col xs={4} sm={4} md={4} lg={4} xl={4}>
							<div className="filterButtons" style={{ justifyContent: "flex-end" }}>
								<Button className="printButton" onClick={() => printAll()}>
									Print
								</Button>
							</div>
						</Col>
					</Row>
				</div>
				<div className="tableDiv">
					{props.managerPanelLoading ? (
						<div className="loaderDiv tableLoader">
							<div>
								<img src={logo} />
							</div>
						</div>
					) : null}
					<Table pagination={false} className="itemsTable" dataSource={data}>
						<Column
							title={(text, record) => {
								return <input type="checkbox" onChange={(ev) => selectAll(ev.target.checked)} />;
							}}
							render={(text, record) => {
								return (
									<input
										type="checkbox"
										onChange={(ev) => selectSep(ev.target.checked, record)}
										checked={selectedArr.indexOf(record) >= 0 ? true : false}
									/>
								);
							}}
						/>
						<Column
							title="Image"
							dataIndex="imgUrl"
							key="imgUrl"
							render={(text, record) => {
								return (
									<img
										onClick={() => console.log(record)}
										className="diamondImage"
										src={`${process.env.REACT_APP_BACKEND_URL}media/${record.fpath
											? record.fpath
											: record.product_pic
												? record.product_pic
												: record.product_pic2
													? record.product_pic2
													: record.product_pic3
											}`}
									/>
								);
							}}
						/>
						<Column
							title="Product Code"
							dataIndex="productCode"
							key="productCode"
							render={(text, record) => {
								return (
									<div className="entryNoBtn" onClick={() => printSingle(record)}>
										{record.certificate_number
											? record.certificate_number
											: record.entryNo
												? record.entryNo
												: record.number
													? record.number
													: ""}
									</div>
								);
							}}
						/>
						<Column
							title="Related Entry No"
							render={(text, record) => {
								return <>{record.relatedEntryNo ? record.relatedEntryNo : ""}</>;
							}}
						/>
						<Column
							title="Date"
							render={(text, record) => {
								return (
									<>
										{record.entry_date
											? record.entry_date
											: record.date
												? record.date
												: record.eptyp_temp
													? record.eptyp_temp
													: ""}
									</>
								);
							}}
						/>
						<Column
							title="Action"
							key="action"
							render={(text, record) => (
								<>
									<Space size="middle">
										<button
											className="editIcon"
											onClick={() => {
												props.history.push(`/small-gemstone-jewellery-form?editId=${record.id}`);
											}}
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="16"
												height="16"
												fill="#001529"
												className="bi bi-pencil-fill"
												viewBox="0 0 16 16"
											>
												<path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
											</svg>
										</button>
									</Space>
									<Space size="middle">
										<Popconfirm
											title="Are you sure to delete this task?"
											onConfirm={(e) => DeleteCert(record.id)}
											okText="Yes"
											cancelText="No"
											className="editIcon"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="16"
												height="16"
												fill="rgb(245, 0, 87)"
												className="bi bi-trash-fill"
												viewBox="0 0 16 16"
											>
												<path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
											</svg>
										</Popconfirm>
									</Space>
								</>
							)}
						/>
					</Table>
				</div>
				<Pagination
					onChange={(ev, size) => {
						setPage(ev);
						setPageSize(size);
					}}
					pageSize={pageSize}
					current={props.page}
					total={props.total}
				/>
			</div>
		</div>
	);
}

const mapStateToProps = (store) => ({
	permission: store.users.permission,
	certificates: store.managerPanel.certificateList ? store.managerPanel.certificateList : [],
	page: store.managerPanel.page,
	total: store.managerPanel.total,
	managerPanelLoading: store.managerPanel.managerPanelLoading,
	branch: store.branchStore.branch,
	userPermissions: store.authentication.userPermissions,
});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			getCertificates,
			deleteCertificate,
			getBranch
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(SmallGemstoneJewellery);
